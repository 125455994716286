import React from "react"
import { graphql } from 'gatsby'
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import buildPage from "../utilities/buildPage";

const AboutTitle = styled.h1`
  text-align: center;
`

const AboutImg = styled.img`
  max-width: 640px;
  width: 100%;
`

const AboutBody = styled.div`
  max-width: 640px;
`

const About = props => (
  <Layout>
    <SEO title="About" />
    <AboutTitle>About</AboutTitle>

    <AboutImg
      src="https://cdn.sanity.io/images/rkolvoq3/production/7e2575e8839cab7142ab7161daeb2b30c1a0308b-973x648.png"
      alt="Photo of Drew Aichele"
    />

    <AboutBody>
    {buildPage(props.data.sanityPages._rawText)}
    </AboutBody>
  </Layout>
)

export default About

export const aboutContent = graphql`
  query aboutContent {
    sanityPages(title: {eq: "About"}) {
      title
      _rawText
    }
  }
`
